





















import { DateTime } from "luxon";
import { Component, Vue } from "vue-property-decorator";
import SchedulerCalendar from "../components/app/scheduler/SchedulerCalendar.vue";
import SidePanel from "../components/app/scheduler/SidePanel.vue";
import AppLayout from "../components/commons/layouts/AppLayout.vue";
import { api } from "../api";
import {
  CalendarQuery,
  CalendarQueryVariables,
} from "../types/gqlGeneratedPrivate";
import { apiClient } from "../utils";

export type CalendarData = Array<{
  date: DateTime;
  hasConflicts: boolean;
  laborers: Array<{
    conflicts: number;
    name: string;
    id: number;
  }>;
}>;

@Component({
  components: {
    AppLayout,
    SchedulerCalendar,
    SidePanel,
  },
})
export default class SchedulerPage extends Vue {
  currentDay = DateTime.now().startOf("day");

  calendarData: CalendarData = [];

  async onMonthChange(date: DateTime) {
    const { data } = await apiClient.callGraphqlPrivate<
      CalendarQuery,
      CalendarQueryVariables
    >({
      ...api.scheduler.calendar,
      variables: {
        input: {
          from: date.startOf("month").toISODate(),
          to: date.endOf("month").toISODate(),
        },
      },
    });
    if (data?.calendar) {
      this.calendarData = data.calendar.map((c) => {
        return {
          date: DateTime.fromISO(c.date),
          hasConflicts: c.hasConflicts,
          laborers: c.laborers.map((l) => ({
            conflicts: l.conflicts,
            name: `${l.firstname} ${l.surname}`,
            id: l.id,
          })),
        };
      });
    }
  }
}
