import { render, staticRenderFns } from "./SidePanel.vue?vue&type=template&id=061ff70c&scoped=true&"
import script from "./SidePanel.vue?vue&type=script&lang=ts&"
export * from "./SidePanel.vue?vue&type=script&lang=ts&"
import style0 from "./SidePanel.vue?vue&type=style&index=0&id=061ff70c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "061ff70c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VList,VListItem,VListItemAction,VListItemContent,VSpacer,VToolbar})
