







































import { DateTime } from "luxon";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Button from "../../commons/inputsAndControls/Button.vue";
import RoundButton from "../../commons/inputsAndControls/RoundButton.vue";
import { toMonthYear } from "../../../filters/datetime";
import { CalendarData } from "../../../views/SchedulerPage.vue";

@Component({
  filters: {
    toMonthYear,
  },
  components: {
    Button,
    RoundButton,
  },
})
export default class SchedulerCalendar extends Vue {
  @Prop({ required: true, type: Object })
  value!: DateTime;

  @Prop({ required: true, type: Array })
  calendarData!: CalendarData;

  weekdays = [1, 2, 3, 4, 5, 6, 0];

  focus = DateTime.now().toISODate();

  isToday(date: string): boolean {
    return this.value.hasSame(DateTime.fromISO(date), "day");
  }

  hasConflict(date: string): boolean {
    return (
      this.calendarData.find((item) =>
        item.date.hasSame(DateTime.fromISO(date), "day")
      )?.hasConflicts ?? false
    );
  }

  previousMonth(): void {
    (this.$refs as any).calendar.prev();
  }

  nextMonth(): void {
    (this.$refs as any).calendar.next();
  }

  formatWeekday(dateData: any): string {
    return new Intl.DateTimeFormat("cs", { weekday: "long" }).format(
      new Date(dateData.date)
    );
  }

  onDayClick(date: string) {
    this.$emit("input", DateTime.fromISO(date));
  }

  @Watch("value")
  onCurrentDayChanged() {
    if (this.value < DateTime.fromISO(this.focus).startOf("month")) {
      this.previousMonth();
    }
    if (this.value > DateTime.fromISO(this.focus).endOf("month")) {
      this.nextMonth();
    }
  }

  @Watch("focus", { immediate: true })
  async onMonthChange() {
    const currentDate = DateTime.fromISO(this.focus);
    this.$emit("month-change", currentDate);
  }
}
