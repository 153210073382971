















































import { DateTime } from "luxon";
import { Component, Prop, Vue } from "vue-property-decorator";
import Button from "../../commons/inputsAndControls/Button.vue";
import RoundButton from "../../commons/inputsAndControls/RoundButton.vue";
import { toLocalDate } from "../../../filters/datetime";
import { CalendarData } from "../../../views/SchedulerPage.vue";
import { Routes } from "../../../enums";

@Component({
  filters: {
    toLocalDate,
  },
  components: {
    Button,
    RoundButton,
  },
})
export default class SidePanel extends Vue {
  @Prop({ required: true, type: Object })
  value!: DateTime;

  @Prop({ required: true, type: Array })
  calendarData!: CalendarData;

  Routes = Routes;

  get currentItem() {
    return this.calendarData.find((item) =>
      item.date.hasSame(this.value, "day")
    );
  }

  get isToday(): boolean {
    return this.value.hasSame(DateTime.now(), "day");
  }

  today(): void {
    this.$emit("input", DateTime.now().startOf("day"));
  }

  prev(): void {
    this.$emit("input", this.value.minus({ days: 1 }));
  }

  next(): void {
    this.$emit("input", this.value.plus({ days: 1 }));
  }
}
