var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{staticClass:"ml-2 mb-6",attrs:{"flat":""}},[_c('RoundButton',{attrs:{"icon":"chevron-left","large":""},on:{"click":_vm.previousMonth}}),_c('h1',{staticClass:"year-month mx-2"},[_vm._v(_vm._s(_vm._f("toMonthYear")(_vm.focus)))]),_c('RoundButton',{attrs:{"icon":"chevron-right","large":""},on:{"click":_vm.nextMonth}})],1),_c('v-sheet',{attrs:{"height":"600"}},[_c('v-calendar',{ref:"calendar",staticClass:"calendar",attrs:{"color":"primary","type":"month","weekdays":_vm.weekdays,"weekday-format":_vm.formatWeekday,"locale":"cs","light":"","category-show-all":"","show-month-on-first":false},scopedSlots:_vm._u([{key:"day-label",fn:function(ref){
var day = ref.day;
var date = ref.date;
var present = ref.present;
return [_c('RoundButton',{staticClass:"day-button",class:{
            'color-accent-blue': present,
            conflict: _vm.hasConflict(date),
            today: _vm.isToday(date),
          },on:{"click":function($event){return _vm.onDayClick(date)}}},[_vm._v(" "+_vm._s(day)+" ")])]}}]),model:{value:(_vm.focus),callback:function ($$v) {_vm.focus=$$v},expression:"focus"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }